<template>
    <v-container fluid>
        <v-form @submit.prevent="getArts('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'art.create', 'art_category_id': $route.params.art_category_id}"
                   color="indigo" fab fixed top right class="v-btn--add-form-top white--text">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="1" style="max-width: 80px">
                                <v-btn :to="{name: 'art_category'}" class="back-btn" link large>
                                    <v-icon>mdi-less-than</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="11">
                                <v-subheader class="headline">{{art_category.name}}</v-subheader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="10">
                                <v-subheader class="headline">{{ $t('search_art') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                <v-btn :to="{name: 'art.create', 'art_category_id': $route.params.art_category_id}" class="white--text" color="indigo">
                                    {{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="description" rules="min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="description" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-earth-box"
                                                  :label="$t('description')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-4">
                        <v-row>
                            <v-col cols="12" class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table :headers="headers" :items="artItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalArts" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')"
                              class="elevation-1" hide-default-footer>
                    <template v-slot:item.description="{ item }">
                       <div  v-html="item.description"></div>
                    </template>
                    <template v-slot:item.image="{ item }">
                        <v-img v-if="item.image" :src="item.image"
                               aspect-ratio="1" class="my-1" width="40" height="40"></v-img>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" class="mr-2" @click="editArt(item)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" :disabled="item.deleted" @click="deleteArt(item)">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
                <v-row v-if="false">
                    <v-col cols="4" v-for="(item, index) in artItems" :key="index">
                        <v-card>

                            <v-img
                                v-if="item.image" :src="item.image"
                                height="125"
                                contain
                                class="grey darken-4"
                            ></v-img>
                            <v-card-text v-html="item.description"></v-card-text>
                            <v-card-subtitle>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" class="mr-2" @click="editArt(item)">
                                            <v-icon>mdi-square-edit-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{$t('edit')}}</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" :disabled="item.deleted" @click="deleteArt(item)">
                                            <v-icon>mdi-delete-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                                </v-tooltip>
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex"

    export default {
        name: "Arts",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                description: null,
                sortBy: "description",
                sortDir: true,
                loading: false,
                options: {},
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 5,
                totalArts: 0,
                artItems: [],
                headers: [
                    {
                        text: this.$t('description'),
                        align: "left",
                        sortable: true,
                        value: "description"
                    },
                    {
                        text: this.$t('image'),
                        align: "left",
                        sortable: false,
                        value: "image",
                        width: 120,
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                ],
                art_category: {},
            }
        },
        computed: {
            ...mapGetters(['lang', 'language', 'itemsPerPage', 'perPageItems']),
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
            this.getArtCategory();
        },
        watch: {
            options: {
                handler() {
                    this.getArts()
                },
                deep: false
            }
        },
        methods: {
            async getArtCategory() {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`admin/art_category/${this.$route.params.art_category_id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.art_category = res.body.data

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_art_category'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            editArt(item) {
                this.$router.push({
                    name: 'art.edit',
                    params: {
                        art_category_id: this.$route.params.art_category_id,
                        id: item.id
                    }
                })
            },
            async getArts(type) {
                this.loading = true
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {}
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc';
                }
                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }
                if (this.description) {
                    params.description = this.description
                }

                params.art_category_id = this.$route.params.art_category_id


                await this.$http
                    .get("admin/art", {
                        params: params,
                    })
                    .then(res => {
                        this.artItems = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalArts = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.artItems = []
                        this.totalArts = 0
                        this.$toastr.error(this.$t('failed_to_get_list_arts'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async deleteArt(item) {
                if (confirm(this.$t('delete_art'))) {
                    var _this = this
                    this.loading = true
                    await this.$http
                        .delete(`admin/art/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('art_has_been_deleted'))
                            this.getArts()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('art_has_not_been_deleted'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
        }
    }
</script>
